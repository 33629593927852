import { css } from '@emotion/react';
import { FC, useState } from 'react';
import { useHistory } from 'react-router-dom';
import SectionHeading from '../SectionHeading';
import Takeover from '../../Takeover';
import Button from '../../Button';
import { spacing, fontStyles, colors } from '../../../styles';
import Hr from '../../Hr';
import externalLink from '../../../assets/externalLink.svg';

interface Props {
  name: string;
  category: string;
  description: string;
  website?: string;
  investorRelationsUrl?: string;
  industry?: string;
  sector?: string;
  ceo?: string;
  employees?: number;
  headquarters?: string;
}

const styles = {
  tagContainer: css`
    display: flex;
    gap: ${spacing.centi};
    margin: -${spacing.centi} 0 ${spacing.centi} 0;
    flex-wrap: wrap;
  `,
  tag: css`
    ${fontStyles.flea}
    color: ${colors.petrolBlue};
    border: 1px solid ${colors.petrolBlue};
    border-radius: 20px;
    padding: ${spacing.milli} ${spacing.centi};
    text-transform: capitalize;
    html[data-theme='dark'] & {
      color: ${colors.skyBlue};
      border-color: ${colors.skyBlue};
    }
  `,
  table: css`
    border-collapse: collapse;
    width: 100%;
  `,
  cell: css`
    width: 50%;
    vertical-align: text-top;
    padding: 0 0 ${spacing.centi} 0;
    html[data-theme='dark'] & {
      color: ${colors.sugar};
    }
  `,
  label: css`
    text-align: left;
    color: ${colors.tungsten};
    ${fontStyles.cat}
  `,
  value: css`
    text-align: right;
    ${fontStyles.roboCat}
    html[data-theme='dark'] & {
      color: ${colors.sugar};
    }
  `,
  shortenedDescription: css`
    ${fontStyles.cat}
    margin: 0 0 ${spacing.regular} 0;
    html[data-theme='dark'] & {
      color: ${colors.sugar};
    }
  `,
  externalLink: css`
    display: block;
    margin-bottom: ${spacing.centi};
    ${fontStyles.roboCat}
    html[data-theme='dark'] & {
      color: ${colors.skyBlue};
    }
  `,
  externalLinkIcon: css`
    vertical-align: text-bottom;
  `,
  actionButtons: css`
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: ${spacing.centi};
    margin-top: ${spacing.regular};
  `,
  actionButton: css`
    ${fontStyles.cat}
    background: ${colors.petrolBlue}10;
    color: ${colors.petrolBlue};
    padding: ${spacing.centi};
    border-radius: 8px;
    text-align: left;
    transition: background 0.2s;
    &:hover {
      background: ${colors.petrolBlue}20;
    }
    html[data-theme='dark'] & {
      background: ${colors.skyBlue}10;
      color: ${colors.skyBlue};
      &:hover {
        background: ${colors.skyBlue}20;
      }
    }
  `,
};

const ExternalLink: FC<{ website?: string; investorRelationsUrl?: string }> = ({ website, investorRelationsUrl }) => {
  if (!website && !investorRelationsUrl) return null;
  if (investorRelationsUrl)
    return (
      <a href={investorRelationsUrl} css={styles.externalLink}>
        Investor relations <img src={externalLink} css={styles.externalLinkIcon} />
      </a>
    );

  return (
    <a href={website} css={styles.externalLink}>
      Website
      <img src={externalLink} css={styles.externalLinkIcon} />
    </a>
  );
};

const shortenDescription = (description: string) => {
  const shortened = description.split(' ').slice(0, 50).join(' ');
  return shortened.match(/[.,:!?]$/g) ? shortened.slice(0, -1) : shortened;
};

const formatCapitalCase = (str: string) => {
  return str
    .split(/[\s-]+/)
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(' ');
};

const Info: FC<Props> = ({
  name,
  category,
  description,
  website,
  investorRelationsUrl,
  industry,
  sector,
  ceo,
  employees,
  headquarters,
}) => {
  const [isDescriptionShown, setIsDescriptionShown] = useState<boolean>(false);
  const history = useHistory();
  const labelToValueMapping = {
    Industry: industry,
    Sector: sector,
    CEO: ceo,
    'Number of employees': employees?.toLocaleString(),
    Headquarters: headquarters,
  };

  const tableContents = Object.entries(labelToValueMapping).filter(([, value]) => !!value);

  if (!description && !website && !investorRelationsUrl && tableContents.length === 0) return null;

  const tags = [
    category && { label: formatCapitalCase(category), type: 'Category' },
    sector && { label: formatCapitalCase(sector), type: 'Sector' },
    industry && { label: formatCapitalCase(industry), type: 'Industry' },
  ].filter(Boolean);

  return (
    <div>
      <Hr />
      <SectionHeading text={`About ${name}`} />
      {tags.length > 0 && (
        <div css={styles.tagContainer}>
          {tags.map(
            (tag) =>
              tag && (
                <span key={tag.type} css={styles.tag}>
                  {tag.label}
                </span>
              ),
          )}
        </div>
      )}
      <ExternalLink website={website} investorRelationsUrl={investorRelationsUrl} />
      {description ? (
        <p css={styles.shortenedDescription}>
          {shortenDescription(description)}
          {shortenDescription(description) !== description && (
            <>
              ...{' '}
              <Button
                onClick={() => setIsDescriptionShown(true)}
                css={css`
                  ${fontStyles.mouse}
                `}
              >
                Read more
              </Button>
            </>
          )}
        </p>
      ) : null}
      <Takeover
        isShown={isDescriptionShown}
        onClose={() => setIsDescriptionShown(false)}
        title={`About ${name}`}
        name="AboutInvestment"
      >
        <p
          css={css`
            ${fontStyles.cat}
          `}
        >
          {description}
        </p>
      </Takeover>
      <div css={styles.actionButtons}>
        <button
          css={styles.actionButton}
          onClick={() => history.push('/chat', { message: `What are the primary products for ${name}` })}
        >
          Products
        </button>
        <button
          css={styles.actionButton}
          onClick={() => history.push('/chat', { message: `What are potential catalysts for ${name} stock?` })}
        >
          Catalysts
        </button>
        <button
          css={styles.actionButton}
          onClick={() => history.push('/chat', { message: `What are the competitive advantages of ${name}?` })}
        >
          Competitive advantages
        </button>
        <button
          css={styles.actionButton}
          onClick={() => history.push('/chat', { message: `What are the key risks for ${name}?` })}
        >
          Key risks
        </button>
      </div>
    </div>
  );
};

export default Info;
