import { FC, useState } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { css } from '@emotion/react';
import { useQuery } from 'react-query';
import * as stores from '../../../stores';
import { fontStyles, spacing, colors } from '../../../styles';
import Button from '../../Button';
import Hr from '../../Hr';
import { getRiskFactors } from '../../../apis/backend';
import SectionHeading from '../SectionHeading';
import externalLink from '../../../assets/externalLink.svg';
import RiskDetailsTakeover from './RiskDetailsTakeover';
import RiskFactorPoint from './RiskFactorPoint';

interface Props extends Pick<RouteComponentProps, 'match'> {
  symbol: string;
}

const RiskFactorsSection: FC<Props> = ({ match, symbol }) => {
  const query = useQuery(['investments', symbol, 'risk_factors'], async () => getRiskFactors(symbol));
  const [detailsTakeoverId, setDetailsTakeoverId] = useState<string>();
  const purchaserInfo = stores.Purchase.useState((s) => s.purchaserInfo);
  const isSubscribed = purchaserInfo?.entitlements?.active[stores.entitlementIdentifier]?.isActive;

  if (query.isLoading || query.isError || !query.data || query.data.length === 0) return <div />;

  const selectedRiskFactor = query.data.find(({ id }) => id === detailsTakeoverId);

  return (
    <>
      <RiskDetailsTakeover
        isShown={!!selectedRiskFactor}
        onClose={() => setDetailsTakeoverId(undefined)}
        keyPoint={selectedRiskFactor?.keyPoint}
        details={selectedRiskFactor?.details}
      />
      <Hr />
      <SectionHeading text="Risk Factors" showProBadge />
      <p
        css={css`
          margin: -${spacing.deci} 0 ${spacing.regular};
          ${fontStyles.cat}
          html[data-theme='dark'] & {
            color: ${colors.sugar};
          }
        `}
      >
        Risk factors are extracted from the company’s{' '}
        <a
          href={query.data[0].sourceDoc}
          css={css`
            white-space: nowrap;
          `}
        >
          10-K filing
          <img
            src={externalLink}
            css={css`
              vertical-align: text-bottom;
            `}
          />
        </a>
        , which is typically more than 50 pages long.
      </p>
      <ul
        css={css`
          margin: 0;
          padding: 0;
          list-style-type: none;
          html[data-theme='dark'] & {
            color: ${colors.sugar};
          }
        `}
      >
        {query.data.slice(0, 2).map((riskFactor) => (
          <li
            key={riskFactor.id}
            css={css`
              margin-bottom: ${spacing.regular};
              html[data-theme='dark'] & {
                color: ${colors.sugar};
              }
            `}
          >
            <RiskFactorPoint
              keyPoint={riskFactor.keyPoint}
              onShowDetails={() => setDetailsTakeoverId(riskFactor.id)}
              lineClamp={5}
            />
          </li>
        ))}
      </ul>

      <div
        css={css`
          margin-top: ${spacing.deci};
        `}
      >
        {isSubscribed ? (
          <Link to={`${match.url}/risk-factors`}>
            <Button variant="secondary" block>
              See all risk factors
            </Button>
          </Link>
        ) : (
          <>
            <Button
              variant="secondary"
              block
              onClick={() => {
                stores.General.update((s) => {
                  s.isSubscriptionTakeoverShown = true;
                });
              }}
            >
              See all risk factors
            </Button>
            <div
              css={css`
                text-align: center;
                color: ${colors.tungsten};
                margin-top: ${spacing.centi};
                ${fontStyles.fairyfly}
                html[data-theme='dark'] & {
                  color: ${colors.sugar};
                }
              `}
            >
              Full access is reserved for subscribers.
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default RiskFactorsSection;
