import { css } from '@emotion/react';
import { FC } from 'react';
import { useQuery } from 'react-query';
import { Link, useRouteMatch } from 'react-router-dom';
import { colors, fontStyles, spacing } from '../../../styles';
import SectionHeading from '../SectionHeading';
import Button from '../../Button';
import Hr from '../../Hr';
import { getInsights } from '../../../apis/backend';
import * as stores from '../../../stores';

interface KeyPoint {
  page: number;
  details: string;
}

interface PotentialRisk {
  page: number;
  details: string;
}

interface Insight {
  id: number;
  keyPoints: KeyPoint[];
  potentialRisks: PotentialRisk[];
  fileUrl: string;
  created: string;
  updated: string;
  summary: string;
  title: string;
  articleDate: string;
  investment: string;
}

interface InsightResponse {
  results: Insight[];
}

interface Props {
  symbol: string;
}

const Insights: FC<Props> = ({ symbol }) => {
  const match = useRouteMatch();
  const purchaserInfo = stores.Purchase.useState((s) => s.purchaserInfo);
  const isSubscribed = purchaserInfo?.entitlements?.active[stores.entitlementIdentifier]?.isActive;

  const isFreeTicker = (ticker: string): boolean => {
    return ['AAPL', 'TSLA'].includes(ticker.toUpperCase());
  };

  const { data, isLoading } = useQuery<InsightResponse>(['investments', symbol, 'insights'], async () => {
    const response = await getInsights(symbol);
    return { results: Array.isArray(response) ? response : [] };
  });

  if (isLoading || !data?.results?.length) return null;

  const latestInsights = data.results.slice(0, 3);

  return (
    <div>
      <Hr />
      <SectionHeading text="Insights" showProBadge />

      <div>
        {latestInsights.map((insight) => {
          const date = new Date(insight.articleDate).toLocaleDateString('en-US', {
            month: 'short',
            day: 'numeric',
            year: 'numeric',
          });

          return (
            <div
              key={insight.id}
              css={css`
                margin-bottom: ${spacing.regular};
              `}
            >
              <div
                css={css`
                  display: flex;
                  justify-content: space-between;
                  align-items: flex-start;
                  margin-bottom: ${spacing.centi};
                `}
              >
                {isSubscribed || isFreeTicker(symbol) ? (
                  <Link
                    to={`${match.url}/insights`}
                    css={css`
                      color: ${colors.petrolBlue};
                      text-decoration: none;
                      ${fontStyles.roboCat}
                      cursor: pointer;

                      html[data-theme='dark'] & {
                        color: ${colors.skyBlue};
                      }
                    `}
                  >
                    {insight.title}
                  </Link>
                ) : (
                  <div
                    onClick={() => {
                      stores.General.update((s) => {
                        s.isSubscriptionTakeoverShown = true;
                      });
                    }}
                    css={css`
                      color: ${colors.petrolBlue};
                      text-decoration: none;
                      ${fontStyles.roboCat}
                      cursor: pointer;

                      html[data-theme='dark'] & {
                        color: ${colors.skyBlue};
                      }
                    `}
                  >
                    {insight.title}
                  </div>
                )}
                <span
                  css={css`
                    color: ${colors.silver};
                    ${fontStyles.roboCat}

                    html[data-theme='dark'] & {
                      color: ${colors.sugar};
                    }
                  `}
                >
                  {date}
                </span>
              </div>

              <p
                css={css`
                  margin: ${spacing.centi} 0;
                  color: ${colors.tungsten};
                  ${fontStyles.cat}

                  html[data-theme='dark'] & {
                    color: ${colors.sugar};
                  }
                `}
              >
                {insight.summary}...
              </p>
            </div>
          );
        })}

        <div
          css={css`
            margin-top: ${spacing.deci};
          `}
        >
          {isSubscribed || isFreeTicker(symbol) ? (
            <Link to={`${match.url}/insights`}>
              <Button variant="secondary" block>
                See all insights ({data.results.length})
              </Button>
            </Link>
          ) : (
            <>
              <Button
                variant="secondary"
                block
                onClick={() => {
                  stores.General.update((s) => {
                    s.isSubscriptionTakeoverShown = true;
                  });
                }}
              >
                See all insights ({data.results.length})
              </Button>
              <div
                css={css`
                  text-align: center;
                  color: ${colors.tungsten};
                  margin-top: ${spacing.centi};
                  ${fontStyles.fairyfly}
                  html[data-theme='dark'] & {
                    color: ${colors.sugar};
                  }
                `}
              >
                Full access is reserved for subscribers.
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Insights;
