import { FC } from 'react';
import { css } from '@emotion/react';
import Card from '../Card';
import { spacing, fontStyles, colors } from '../../styles';

interface Props {
  name: string;
  imageSrc: string;
  isLocked?: boolean;
}

const CollectionCard: FC<Props> = ({ name, imageSrc, isLocked }) => (
  <Card
    css={css`
      height: 100%;
      width: 100%;
      color: ${colors.petrolBlue};
      text-align: center;
      position: relative;
      padding: ${spacing.centi};
      ${fontStyles.fatCat};
      ${isLocked &&
      `
        background-color: ${colors.cloud};
      `}

      html[data-theme='dark'] & {
        ${isLocked &&
        `
        background-color: ${colors.tungsten};
      `}
      }
    `}
  >
    {isLocked && (
      <div
        css={css`
          position: absolute;
          right: 0;
          top: 0;
          padding: inherit;
          background: ${colors.petrolBlue};
          color: ${colors.sugar};
          padding: 2px ${spacing.milli};
          border-radius: 4px;
          ${fontStyles.flea}
          text-transform: uppercase;
          letter-spacing: 0.5px;
        `}
      >
        Pro
      </div>
    )}
    <img
      src={imageSrc}
      css={css`
        width: 90%;
      `}
    />
    <div
      css={css`
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      `}
    >
      {name}
    </div>
  </Card>
);

export default CollectionCard;
