import { FC, useState, useEffect } from 'react';
import { Spinner } from '@chakra-ui/react';
import { useQuery } from 'react-query';
import { css } from '@emotion/react';
import { useParams } from 'react-router-dom';
import { SortingRule } from 'react-table';
import Page from '../Page';
import Header from '../Header';
import { getCollection, Collection } from '../../apis/backend';
import ErrorSection from '../ErrorSection';
import { getCollectionImages } from '../../utils/getImages';
import FeedbackFooter from '../FeedbackFooter';
import {
  FilterKey,
  InvestmentTable,
  ColumnSelection,
  ColumnSelectionType,
  translateIdToSelection,
} from '../InvestmentTable';
import { spacing, fontStyles, colors } from '../../styles';

const Content: FC<Collection> = ({
  imageId,
  description,
  defaultMetric,
  defaultSortMetric,
  defaultSortDesc,
  whyItMatters,
  investments,
  updated,
}) => {
  const [columnSelections, setColumnSelections] = useState<ColumnSelection[]>(
    window.history.state?.columnSelections || [
      { type: ColumnSelectionType.Name },
      { type: ColumnSelectionType.BottomLine },
      translateIdToSelection(defaultMetric),
    ],
  );
  const [sortingRule, setSortingRule] = useState<SortingRule<string>>(
    window.history.state?.sortingRule ||
      (defaultSortMetric ? { id: defaultSortMetric, desc: defaultSortDesc } : { id: 'bottomLine' }),
  );
  const [filters, setFilters] = useState<Partial<Record<FilterKey, boolean>>>(
    window.history.state?.filters || {
      large: false,
      favorableProfits: false,
      favorableGrowth: false,
      favorablePrice: false,
    },
  );
  const [imageSrc, setImageSrc] = useState(getCollectionImages(imageId));

  useEffect(() => {
    window.history.replaceState({ columnSelections, sortingRule, filters }, '');
  }, [columnSelections, sortingRule, filters]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const handleThemeChange = () => {
      setImageSrc(getCollectionImages(imageId));
    };

    window.addEventListener('themeChanged', handleThemeChange);
    return () => window.removeEventListener('themeChanged', handleThemeChange);
  }, [imageId]);

  return (
    <>
      <div
        css={css`
          padding: ${spacing.regular} ${spacing.deci};
          border-radius: 8px;
          box-shadow: 0 2px 3px #e5e5e5;
          border: 1px solid ${colors.fog};
          background-color: ${colors.sugar};
          html[data-theme='dark'] & {
            background-color: ${colors.liquorice};
            color: ${colors.sugar};
          }
        `}
      >
        <div
          css={css`
            display: flex;
            color: ${colors.silver};
            html[data-theme='dark'] & {
              color: ${colors.sugar};
            }
            ${fontStyles.fairyfly}
          `}
        >
          <div
            css={css`
              text-align: center;
            `}
          >
            <img
              src={imageSrc}
              css={css`
                height: 90px;
                width: 90px;
              `}
            />
            <div>Updated</div>
            <div>{updated} ago</div>
          </div>
          <div
            css={css`
              margin-left: ${spacing.regular};
            `}
          >
            <h2
              css={css`
                color: ${colors.tungsten};
                margin: 0 0 ${spacing.milli} 0;
                ${fontStyles.flea}
                html[data-theme='dark'] & {
                  color: ${colors.sugar};
                }
              `}
            >
              Description
            </h2>
            <p
              css={css`
                margin: 0;
              `}
            >
              {description}
            </p>
            {whyItMatters ? (
              <div>
                <h2
                  css={css`
                    color: ${colors.tungsten};
                    margin: ${spacing.deci} 0 ${spacing.milli} 0;
                    ${fontStyles.flea}
                    html[data-theme='dark'] & {
                      color: ${colors.sugar};
                    }
                  `}
                >
                  Why it matters
                </h2>
                <p
                  css={css`
                    margin: 0;
                  `}
                >
                  {whyItMatters}
                </p>
              </div>
            ) : null}
          </div>
        </div>
      </div>
      <div
        css={css`
          margin-top: ${spacing.deka};
        `}
      >
        <InvestmentTable
          investments={investments}
          columnSelections={columnSelections}
          onChangeColumnSelections={(index, value) => {
            if (columnSelections) {
              const updatedSelections = [...columnSelections];
              updatedSelections[index] = value;
              setColumnSelections(updatedSelections);
            }
          }}
          sortingRule={sortingRule}
          onChangeSortingRule={(rule) => {
            setSortingRule(rule);
          }}
          filters={filters}
          onSetFilter={(key) => {
            setFilters({ ...filters, [key]: !filters[key] });
          }}
          showBookmark={true}
        />
      </div>
      <FeedbackFooter />
    </>
  );
};

const CollectionPage: FC = () => {
  const { collectionId } = useParams<{ collectionId?: string }>();
  const query = useQuery(
    ['investments', 'collections', collectionId],
    async () => (collectionId ? getCollection(collectionId) : undefined),
    { enabled: !!collectionId },
  );

  if (!collectionId) return null;

  return (
    <Page showNavigationBar>
      <div
        css={css`
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          background-color: ${colors.fog};
          height: 230px;
          z-index: -1;
        `}
      />
      <Header
        headingText={query.data?.name || ''}
        css={css`
          background-color: ${colors.fog};
          html[data-theme='dark'] & {
            background-color: ${colors.liquorice};
            color: ${colors.sugar};
          }
        `}
      />
      {(() => {
        if (query.isLoading)
          return (
            <div
              css={css`
                text-align: center;
                padding-top: 40px;
              `}
            >
              <Spinner color={colors.cloud} size="lg" />
            </div>
          );
        if (query.isError || !query.data)
          return (
            <div
              css={css`
                margin-top: 40vh;
              `}
            >
              <ErrorSection text="Problem loading collection." onRetry={() => query.refetch()} />
            </div>
          );
        return <Content {...query.data} />;
      })()}
    </Page>
  );
};

export default CollectionPage;
