import { FC, useState, useEffect } from 'react';
import { Spinner } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { css } from '@emotion/react';
import { useQuery } from 'react-query';
import { getCollectionsList } from '../../../apis/backend';
import CollectionCard from '../../CollectionCard';
import { colors, spacing, fontStyles } from '../../../styles';
import ErrorSection from '../../ErrorSection';
import { getCollectionImages } from '../../../utils/getImages';
import Button from '../../Button';
import * as stores from '../../../stores';

const styles = {
  section: css`
    margin-bottom: ${spacing.deci};
  `,
  sectionTitle: css`
    color: ${colors.liquorice};
    margin-bottom: ${spacing.milli};
    ${fontStyles.shark};
    html[data-theme='dark'] & {
      background-color: ${colors.liquorice};
      color: ${colors.sugar};
    }
    font-size: 18.4px;
  `,
  horizontalList: css`
    display: flex;
    overflow-x: auto;
    gap: ${spacing.deci};
    padding: ${spacing.centi} 0;
    -webkit-overflow-scrolling: touch;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }
  `,
  item: css`
    flex: 0 0 auto;
    width: 140px;
    position: relative;

    &::before {
      content: '';
      padding-bottom: 120%;
      display: block;
    }
  `,
  itemContent: css`
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
    text-align: center;
  `,
  cardWrapper: css`
    width: 100%;
    height: 100%;
  `,
};

// Define sections and their titles
const SECTIONS = {
  "Don't know where to get started?": 'Get started',
  'Follow famous investors': 'Follow famous investors',
  Leaderboards: 'See the leaderboard',
  'Chase high flyers': 'Chase high flyers',
  'Invest in value': 'Invest in value',
  'Follow trends': 'Follow trends',
  'Find beat-up stocks': 'Find beat-up stocks',
  'Invest in specific regions': 'Invest in specific regions',
  'Invest in a particular sector': 'Explore by industry',
};

const CollectionList: FC = () => {
  const query = useQuery(['investments', 'collections'], async () => getCollectionsList());
  const purchaserInfo = stores.Purchase.useState((s) => s.purchaserInfo);
  const [imageMap, setImageMap] = useState<Record<string, string>>({});
  const [collectionsBySection, setCollectionsBySection] = useState<Record<string, any[]>>({});

  useEffect(() => {
    const updateImages = () => {
      const newImageMap: Record<string, string> = {};
      query.data?.forEach((collection) => {
        const imageSrc = getCollectionImages(collection.imageId);
        if (imageSrc && collection.imageId) {
          newImageMap[collection.imageId] = imageSrc;
        }
      });
      setImageMap(newImageMap);
    };

    updateImages();

    window.addEventListener('themeChanged', updateImages);
    return () => window.removeEventListener('themeChanged', updateImages);
  }, [query.data]);

  useEffect(() => {
    if (query.data) {
      // Group collections by section
      const grouped = query.data.reduce(
        (acc, collection) => {
          console.log(collection);
          const section = collection.section || 'OTHER';
          if (!acc[section]) {
            acc[section] = [];
          }
          acc[section].push(collection);
          return acc;
        },
        {} as Record<string, any[]>,
      );

      setCollectionsBySection(grouped);
    }
  }, [query.data]);

  const renderCollectionCard = (collection: any) => {
    const imageSrc = imageMap[collection.imageId ?? ''];
    if (!imageSrc) return null;

    const isRestricted =
      collection.restrictedToSubscribers &&
      !purchaserInfo?.entitlements?.active[stores.entitlementIdentifier]?.isActive;

    const cardComponent = <CollectionCard name={collection.name} imageSrc={imageSrc} isLocked={isRestricted} />;

    return (
      <div key={collection.id} css={styles.item}>
        <div css={styles.itemContent}>
          <div css={styles.cardWrapper}>
            {isRestricted ? (
              <Button
                onClick={() =>
                  stores.General.update((s) => {
                    s.isSubscriptionTakeoverShown = true;
                  })
                }
                css={css`
                  height: 100%;
                  width: 100%;
                `}
              >
                {cardComponent}
              </Button>
            ) : (
              <Link to={`/collection/${collection.id}`}>{cardComponent}</Link>
            )}
          </div>
        </div>
      </div>
    );
  };

  if (query.isLoading)
    return (
      <div
        css={css`
          text-align: center;
          margin-top: ${spacing.deka};
        `}
      >
        <Spinner color={colors.cloud} size="lg" />
      </div>
    );

  if (query.isError || !query.data)
    return (
      <div
        css={css`
          margin-top: ${spacing.deka};
        `}
      >
        <ErrorSection text="Problem fetching collections." onRetry={() => query.refetch()} />
      </div>
    );

  return (
    <div>
      {Object.entries(SECTIONS).map(([key, title]) => {
        const sectionCollections = collectionsBySection[key] || [];
        if (sectionCollections.length === 0) return null;

        return (
          <section key={key} css={styles.section}>
            <h2 css={styles.sectionTitle}>{title}</h2>
            <div css={styles.horizontalList}>
              {sectionCollections.map((collection) => renderCollectionCard(collection))}
            </div>
          </section>
        );
      })}
    </div>
  );
};

export default CollectionList;
