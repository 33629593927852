import axios from 'axios';
import { isNil, mapValues } from 'lodash';
import { parse } from 'date-fns';
import { ColumnId } from '../components/InvestmentTable/columns';
import { TimeRange } from '../types';
import { backendUrl } from '../constants';

const request = axios.create({
  baseURL: backendUrl,
});

export interface BasicInvestment {
  latestPrice: number;
  changePercent1d: number;
  changePercent1w: number;
  changePercent1m: number;
  changePercent3m: number;
  changePercentYtd: number;
  changePercent1y: number;
  changePercent5y: number;
  symbol: string;
  name: string;
  marketCap: number;
  category: string;
  sector: string;
  industry: string;
  week52High: number;
  week52Low: number;
  description: string;
  website?: string;
  ceo?: string;
  employees?: number;
  headquarters?: string;
  bottomLine?: {
    growthRating: Rating;
    insiderRating: Rating;
    priceRating: Rating;
    profitRating: Rating;
    returnRating: Rating | null;
    riskRating: Rating | null;
    sizeRating: Rating | null;
    feesRating: Rating | null;
  };
}

export type GetInvestmentsResponse = BasicInvestment[];
export const getBasicInvestments = async (symbols: string[]): Promise<GetInvestmentsResponse> =>
  (await request.get(`/investments/?symbols=${symbols.join(',')}`)).data.results;

export const getBasicInvestment = async (symbol: string): Promise<BasicInvestment> =>
  (await request.get(`/investments/${symbol}/`)).data.results;

const generateDate = (result: any) => {
  if (!result.date) return result;

  const date = result.minute
    ? parse(`${result.date} ${result.minute}`, 'yyyy-MM-dd HH:mm', new Date())
    : parse(result.date, 'yyyy-MM-dd', new Date());
  return { ...result, date };
};

export const getCharts = async (symbol: string): Promise<Record<TimeRange, ChartElement[]>> => {
  const response = await request.get(`/investments/${symbol}/charts/`, {
    params: {
      wiims: true,
    },
  });
  return mapValues(response.data.results, (chartElements) =>
    chartElements.map(generateDate).filter((result: any) => !isNil(result.price) && !isNil(result.date)),
  ) as {
    [index in TimeRange]: ChartElement[];
  };
};

export const getStockPeers = async (symbol: string): Promise<BasicInvestment[]> =>
  (await request.get(`/investments/${symbol}/peers/`)).data.results;

export interface Stats {
  revenue: number;
  grossMargin: number;
  netMargin: number;
  operatingCashflowMargin1y: number;
  freeCashflowMargin: number;
  earnings: number;
  revenueGrowth: number;
  earningsGrowth: number;
  threeYearRevenueGrowth: number;
  threeYearEarningsGrowth: number;
  rdSpend: number;
  priceToEarnings: number;
  priceToEarningsGrowth: number;
  priceToBook: number;
  priceToSales: number;
  enterpriseValue: number;
  evToSales: number;
  evToSalesGrowth: number;
  evToEbitda: number;
  evToGrossProfit: number;
  evToCashflow: number;
  evToCashflowGrowth: number;
  ebitda1y: number;
  cashflowYield: number;
  earningsYield: number;
  returnOnCapital: number;
  returnOnEquity: number;
  returnOnAssets: number;
  salesOnCapital: number;
  cashflowReturnOnCapital: number;
  assets: number;
  cash: number;
  debt: number;
  cashToDebt: number;
  equityToDebt: number;
  marketCap: number;
  ebitda: number;
  freeCashFlow: number;
  dividendYield: number;
  rdMargin1y: number;
  salesMargin1y: number;
  shareGrowth1q: number;
  shareGrowthQq: number;
  dollarsBought: number;
  dollarsSold: number;
  dollarsExercised: number;
  dollarsGranted: number;
  dollarsOther: number;
  netDollarsTraded: number;
  netPercentTraded: number;
  totalCapital: number;
  beta: number;
  shortPercent: number;
  latestReportDate: string;
}
export const getStockStats = async (symbol: string): Promise<Stats> =>
  (await request.get(`/investments/${symbol}/stats/`)).data.results;

export interface Holding {
  name: string;
  weight: string;
}
export const getHoldings = async (symbol: string): Promise<Holding[]> =>
  (await request.get(`/investments/${symbol}/holdings/`)).data.results;

export interface GetOverviewResponse {
  attributes: string[];
  items: { title: string; text: string; rating: number }[];
}
export const getOverview = async (symbol: string): Promise<GetOverviewResponse> =>
  (await request.get(`/investments/${symbol}/overview/`)).data.results;

export enum Rating {
  Exceptional = 'EXCEPTIONAL',
  Favorable = 'FAVORABLE',
  Average = 'AVERAGE',
  Unfavorable = 'UNFAVORABLE',
  Dangerous = 'DANGEROUS',
  Missing = 'MISSING',
}

export interface GetBottomLineResponse {
  details: {
    rating: Rating;
    title: string;
    descriptions: string[];
  }[];
}

export const getBottomLine = async (symbol: string): Promise<GetBottomLineResponse> =>
  (await request.get(`/investments/${symbol}/bottom_line_v2/`)).data.results;

export interface NewsArticle {
  articleSource: string;
  articleUrl: string;
  headline: string;
  imageUrl: string;
  publishDateDisplayText: string;
  hasPaywall: boolean;
}
export const getNews = async (symbol: string): Promise<NewsArticle[]> =>
  (await request.get(`/investments/${symbol}/news/`)).data.results;

export type CollectionInvestment = BasicInvestment & { bottomLine?: { [key: string]: Rating } };
export interface Collection {
  id: number;
  active: boolean;
  restrictedToSubscribers: boolean;
  name: string;
  description: string;
  whyItMatters?: string;
  updated: string;
  imageId?: string;
  investments: CollectionInvestment[];
  defaultMetric: ColumnId;
  defaultSortMetric?: ColumnId;
  defaultSortDesc?: boolean;
  sector: string;
  section: string;
}
export const getCollection = async (id: string): Promise<Collection> => (await request.get(`/collections/${id}/`)).data;

export const getCollectionsList = async (): Promise<Omit<Collection, 'investments'>[]> =>
  (
    await request.get(`/collections/`, {
      params: {
        version: 2,
        // show_all: true,
      },
    })
  ).data.results;

export const getRelatedCollections = async (symbol: string): Promise<Omit<Collection, 'investments'>[]> =>
  (
    await request.get(`/investments/${symbol}/collections/`, {
      params: {
        version: 2,
      },
    })
  ).data.results;

export interface Wiim {
  title: string;
  created: string;
  updated: string;
}
export const getWiims = async (
  symbols: string[],
  { dateFrom }: { dateFrom?: string } = {},
): Promise<{ [symbol: string]: Wiim[] }> => {
  const querystring = new URLSearchParams({
    symbols: symbols.join(','),
    ...(dateFrom && { dateFrom }),
  }).toString();
  return (await request.get(`/investments/wiims/?${querystring}`)).data.results;
};

interface DeviceRegistration {
  notificationTypes: string[];
  watchlistSymbols: string[];
}

export const registerDevice = async (token: string, platform: string, config: DeviceRegistration) => {
  return (
    await request.post('/api/register-device/', {
      token,
      platform,
      notificationTypes: config.notificationTypes,
      watchlistSymbols: config.watchlistSymbols,
    })
  ).data;
};

export interface RiskFactor {
  id: string;
  keyPoint: string;
  details?: string;
  sourceDoc: string;
}
export const getRiskFactors = async (symbol: string): Promise<RiskFactor[]> => {
  return (await request.get(`/investments/${symbol}/risk_factors`)).data.results;
};

export const sendFeedback = async (data: { text: string; email?: string }) => {
  return (await request.post(`/feedback/`, data)).data.results;
};

export interface Insight {
  id: number;
  keyPoints: {
    page: number;
    details: string;
  }[];
  potentialRisks: {
    page: number;
    details: string;
  }[];
  fileUrl: string;
  created: string;
  updated: string;
  summary: string;
  title: string;
  articleDate: string;
  investment: string;
}

export const getInsights = async (symbol: string): Promise<Insight[]> => {
  return (await request.get(`/investments/${symbol}/insights/`)).data.results;
};

export interface ChatMessage {
  role: 'user' | 'assistant';
  content: string;
}

// New interface for API messages
interface ApiMessage {
  role: 'user' | 'assistant';
  content: string;
}

export interface ChatResponse {
  text: string;
  citations: string[];
}

export const sendChatMessage = async (history: ApiMessage[]): Promise<ChatResponse> => {
  return (
    await request.post('/chat/', {
      messages: history,
    })
  ).data;
};

export interface SuggestedQuestionsResponse {
  questions: string[];
}

export const getSuggestedQuestions = async (history: ApiMessage[]): Promise<SuggestedQuestionsResponse> => {
  return (
    await request.post('/suggested_questions/', {
      messages: history,
    })
  ).data;
};

export interface GetEtfBottomLineResponse {
  symbol: string;
  details: {
    title: string;
    description: string;
    rating: Rating;
  }[];
}

export const getEtfBottomLine = async (symbol: string): Promise<GetEtfBottomLineResponse> =>
  (await request.get(`/investments/${symbol}/etf_bottom_line/`)).data.results;
