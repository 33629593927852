import { css } from '@emotion/react';
import { FC } from 'react';
import { colors, fontStyles, spacing } from '../../styles';

interface Props {
  text: string;
  showProBadge?: boolean;
}

const SectionHeading: FC<Props> = ({ text, showProBadge }) => (
  <h3
    css={css`
      ${fontStyles.horse};
      margin: 0 0 ${spacing.regular} 0;
      display: flex;
      align-items: center;
      gap: ${spacing.centi};
      html[data-theme='dark'] & {
        color: ${colors.sugar};
      }
    `}
  >
    {text}
    {showProBadge && (
      <span
        css={css`
          background: ${colors.petrolBlue};
          color: ${colors.snow};
          padding: 1px ${spacing.milli};
          border-radius: 4px;
          ${fontStyles.flea}
          text-transform: uppercase;
          font-weight: 500;
          position: relative;
          top: 2px;
        `}
      >
        Pro
      </span>
    )}
  </h3>
);

export default SectionHeading;
