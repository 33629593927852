import { css } from '@emotion/react';
import { useQuery } from 'react-query';
import { useState, FC } from 'react';
import { useParams, Route, RouteComponentProps, Link } from 'react-router-dom';
import { Spinner } from '@chakra-ui/react';
import Page from '../Page';
import { TimeRange } from '../../types';
import { colors, spacing, calculateChangeColor, fontStyles } from '../../styles';
import Header from '../Header';
import { getBasicInvestment, getCharts } from '../../apis/backend';
import { reportError } from '../../utils/reporting';
import ErrorSection from '../ErrorSection';
import TimeRangeRadios from '../TimeRangeRadios';
import FeedbackFooter from '../FeedbackFooter';
import BottomLineExplanationTakeover from '../BottomLineExplanationTakeover';
import * as stores from '../../stores';
import { buildDateText } from './Chart/utils';
import Chart, { ChartNewsData } from './Chart';
import BasicInfo from './BasicInfo';
import Overview from './Overview';
import Related from './Related';
import Holdings from './Holdings';
import KeyStatsPage from './KeyStatsPage';
import RiskFactorsPage from './riskFactors/RiskFactorsPage';
import RiskFactorsSection from './riskFactors/RiskFactorsSection';
import BottomLine from './BottomLine';
import Info from './Info';
import Insights from './Insights';
import AllInsightsPage from './Insights/AllInsightsPage';
import wiimGraphic from './wiim.svg';

type AllowedTimeRange = Exclude<TimeRange, TimeRange.ytd>;

const SymbolPage: FC<RouteComponentProps> = ({ match }) => {
  const { symbol } = useParams<{ symbol?: string }>();
  const [isBottomLineExplanationShown, setIsBottomLineExplanationShown] = useState(false);
  const purchaserInfo = stores.Purchase.useState((s) => s.purchaserInfo);
  const isSubscribed = purchaserInfo?.entitlements?.active[stores.entitlementIdentifier]?.isActive;

  const baseSymbol = symbol?.toUpperCase();

  const basicInvestmentQuery = useQuery(['investments', baseSymbol], async () => {
    return baseSymbol ? getBasicInvestment(baseSymbol) : undefined;
  });
  const chartsQuery = useQuery(['investments', 'charts', baseSymbol], async () => {
    try {
      return baseSymbol ? await getCharts(baseSymbol) : undefined;
    } catch (error) {
      console.error('Error fetching chart data:', error);
      throw error; // Let react-query handle the error state
    }
  });
  const [selectedTimeRange, setSelectedTimeRange] = useState<AllowedTimeRange>(TimeRange.months1);
  const [selectedNewsChartElement, setSelectedNewsChartElement] = useState<ChartNewsData | undefined>();
  const [scannedChartElement, setScannedChartElement] = useState<ChartNewsData | undefined>();

  const changeRatioMap: { [index in AllowedTimeRange]: number } | undefined = basicInvestmentQuery.data && {
    '1d': basicInvestmentQuery.data.changePercent1d,
    '1w': basicInvestmentQuery.data.changePercent1w,
    '1m': basicInvestmentQuery.data.changePercent1m,
    '3m': basicInvestmentQuery.data.changePercent3m,
    '1y': basicInvestmentQuery.data.changePercent1y,
    '5y': basicInvestmentQuery.data.changePercent5y,
  };

  const changeRatio = selectedTimeRange && changeRatioMap ? changeRatioMap[selectedTimeRange] : undefined;

  const selectedChart: ChartElement[] = (selectedTimeRange && chartsQuery.data?.[selectedTimeRange]) || [];

  if (!symbol || !baseSymbol) {
    reportError(new Error('Loading investments page without symbol'));
    return <div />;
  }

  return (
    <Page showNavigationBar>
      <Header
        symbol={baseSymbol}
        headingText={baseSymbol}
        category={basicInvestmentQuery.data?.category}
        name={basicInvestmentQuery.data?.name}
      />
      <BottomLineExplanationTakeover
        isShown={isBottomLineExplanationShown}
        onClose={() => setIsBottomLineExplanationShown(false)}
      />
      <Route path={`${match.url}/risk-factors`} render={() => <RiskFactorsPage symbol={symbol} />} />
      <Route path={`${match.url}/stats`} render={() => <KeyStatsPage baseSymbol={baseSymbol} />} />
      <Route path={`${match.url}/insights`} render={() => <AllInsightsPage symbol={symbol} />} />
      <Route
        exact
        path={match.url}
        render={() =>
          basicInvestmentQuery.isError ? (
            <div
              css={css`
                margin-top: 40vh;
              `}
            >
              <ErrorSection
                text={`Problem fetching data for ${baseSymbol}.`}
                onRetry={() => {
                  basicInvestmentQuery.refetch();
                  chartsQuery.refetch();
                }}
              />
            </div>
          ) : (
            <div>
              <div
                css={css`
                  padding-bottom: ${spacing.regular};
                `}
              >
                <BasicInfo
                  name={basicInvestmentQuery.data?.name}
                  price={scannedChartElement?.price || basicInvestmentQuery.data?.latestPrice}
                  changeRatio={scannedChartElement?.changeRatio || changeRatio}
                  dateText={
                    basicInvestmentQuery.data && selectedTimeRange
                      ? buildDateText(selectedChart, selectedTimeRange, scannedChartElement)
                      : ''
                  }
                />
              </div>

              {basicInvestmentQuery.isLoading ? (
                <div
                  css={css`
                    position: fixed;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                  `}
                >
                  <Spinner color={colors.cloud} size="lg" />
                </div>
              ) : (
                <>
                  <Chart
                    css={css`
                      margin-bottom: ${spacing.regular};
                    `}
                    isLoading={chartsQuery.isLoading}
                    isError={chartsQuery.isError}
                    onRetry={() => chartsQuery.refetch()}
                    strokeColor={calculateChangeColor(changeRatio)}
                    charts={chartsQuery.data}
                    timeRange={selectedTimeRange}
                    onSelectNews={(chartElement) => {
                      // Set a timeout to prevent tapping on a link in a wiim from closing the element before the tap can register
                      setTimeout(() => setSelectedNewsChartElement(chartElement), 50);
                    }}
                    selectedNewsElement={selectedNewsChartElement}
                    onScanPrice={(chartElement) => setScannedChartElement(chartElement)}
                    scannedPrice={scannedChartElement}
                    ticker={baseSymbol}
                  />

                  <TimeRangeRadios
                    timeRanges={(chartsQuery.data
                      ? ([
                          TimeRange.days1,
                          TimeRange.weeks1,
                          TimeRange.months1,
                          TimeRange.months3,
                          TimeRange.years1,
                          TimeRange.years5,
                        ] as const)
                      : []
                    ).filter((time) => chartsQuery.data?.[time]?.length)}
                    selectedTimeRange={selectedTimeRange}
                    onRadioChange={(time) => {
                      setSelectedTimeRange(time);
                      setSelectedNewsChartElement(undefined);
                      setScannedChartElement(undefined);
                    }}
                  />
                  {!isSubscribed && (
                    <div
                      css={css`
                        margin: ${spacing.regular} 0 0 0;
                        display: flex;
                        align-items: center;

                        --chart-color: ${colors.petrolBlue};
                        --shadow-color: #f5f5f5;

                        html[data-theme='dark'] & {
                          --chart-color: ${colors.skyBlue};
                          --shadow-color: ${colors.tungsten};
                        }
                      `}
                    >
                      <div
                        css={css`
                          flex: 0 0 auto;
                          width: 120px;
                        `}
                      >
                        <img src={wiimGraphic} alt="" />
                      </div>
                      <div
                        css={css`
                          flex: 1;
                          min-width: 0;
                          text-align: left;
                        `}
                      >
                        <div
                          css={css`
                            ${fontStyles.lion}
                            margin-bottom: ${spacing.centi};
                            color: ${colors.liquorice};
                            html[data-theme='dark'] & {
                              color: ${colors.sugar};
                            }
                          `}
                        >
                          See why it&apos;s moving
                          <span
                            css={css`
                              display: inline-block;
                              margin-left: ${spacing.milli};
                              background: ${colors.petrolBlue};
                              color: ${colors.sugar};
                              padding: 2px ${spacing.milli};
                              border-radius: 4px;
                              ${fontStyles.flea}
                              text-transform: uppercase;
                              letter-spacing: 0.5px;
                              vertical-align: middle;
                            `}
                          >
                            Pro
                          </span>
                        </div>

                        <div
                          css={css`
                            ${fontStyles.cat}
                            color: ${colors.silver};
                            margin-bottom: ${spacing.centi};
                            html[data-theme='dark'] & {
                              color: ${colors.sugar};
                            }
                          `}
                        >
                          {['AAPL', 'TSLA'].includes(baseSymbol)
                            ? 'Try it free with AAPL and TSLA!'
                            : 'Subscribe to see stock news right on the chart.'}
                        </div>
                        <Link
                          to="#"
                          onClick={(e) => {
                            e.preventDefault();
                            stores.General.update((s) => {
                              s.isSubscriptionTakeoverShown = true;
                            });
                          }}
                          css={css`
                            color: ${colors.petrolBlue};
                            text-decoration: none;
                            ${fontStyles.mouse}

                            html[data-theme='dark'] & {
                              color: ${colors.skyBlue};
                            }

                            &:hover {
                              text-decoration: underline;
                            }
                          `}
                        >
                          Learn more
                        </Link>
                      </div>
                    </div>
                  )}
                </>
              )}
              {basicInvestmentQuery.data && (
                <div>
                  <Info {...basicInvestmentQuery.data} />
                  <div
                    css={css`
                      margin-top: ${spacing.deka};
                    `}
                  >
                    {['STOCK', 'ADR'].includes(basicInvestmentQuery.data?.category) && (
                      <>
                        <BottomLine symbol={baseSymbol} match={match} />
                        <Insights symbol={baseSymbol} />
                      </>
                    )}
                    {['MUTUAL_FUND', 'ETF'].includes(basicInvestmentQuery.data?.category) && (
                      <Overview symbol={baseSymbol} />
                    )}
                  </div>

                  <RiskFactorsSection {...basicInvestmentQuery.data} match={match} />

                  {['MUTUAL_FUND', 'ETF'].includes(basicInvestmentQuery.data?.category) && (
                    <div>
                      <Holdings symbol={baseSymbol} />
                    </div>
                  )}

                  <Related symbol={baseSymbol} />
                  <FeedbackFooter />
                </div>
              )}
            </div>
          )
        }
      />
    </Page>
  );
};

export default SymbolPage;
